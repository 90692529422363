// Shared methods across forms
export default {
  methods: {
    updateTags() {
      // accepting pasting a comma saperated tags
      if (this.tags && this.tags.split(',').length > 1) {
        this.formData.tags = this.formData.tags.concat(
          this.tags.split(',').filter(
            // filter out already existing tags & any empty tags with just spaces
            (term) =>
              !this.formData.tags.includes(term) && term.trim().length > 0,
          ),
        );
        this.tags = '';
      }
    },
  },
};
