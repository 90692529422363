// © 2021 Tfarraj
import Vue from 'vue';
import Vuex from 'vuex';

import Config from '@/config';
import auth from './modules/auth';
import comments from './modules/comment';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    playUrl: `${Config.apiUrl}/api/v1/play`,
    // url: Config.apiUrl,
    // token: localStorage.getItem('token') || null,
    // user: JSON.parse(localStorage.getItem('user')) || null,
    // isUserLoggedIn: localStorage.getItem('token') || false
    studioSearchText: '',
  },
  getters: {
    getPlayUrl: (state) => state.playUrl,
    // getUrl: (state) => {
    //   return state.url
    // },
    // getIsAuthenticated: (state) => {
    //   return state.isUserLoggedIn
    // },
    // getToken: (state) => {
    //   return state.token
    // },
    // getUser: (state) => {
    //   return state.user
    // }
  },
  mutations: {
    // setToken(state, token) {
    //   state.token = token
    //   if (token) {
    //     state.isUserLoggedIn = true
    //   } else {
    //     state.isUserLoggedIn = false
    //   }
    // },
    // setUser(state, user) {
    //   state.user = user
    // },
    // clearAuthData(state) {
    //   state.token = null
    //   state.user = null
    //   state.isUserLoggedIn = false
    // }
    setStudioSearchText(state, studioSearchText) {
      state.studioSearchText = studioSearchText;
    },
  },
  actions: {
    // setToken({ commit }, token) {
    //   commit('setToken', token)
    //   localStorage.setItem('token', token)
    // },
    // signin({ commit }, user) {
    //   commit('setUser', user)
    //   localStorage.setItem('user', JSON.stringify(user))
    // },
    // signOut({ commit }) {
    //   commit('clearAuthData')
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('user')
    // }
    setStudioSearchText({ commit }, studioSearchText) {
      commit('setStudioSearchText', studioSearchText);
    },
  },
  modules: { auth, comments },
});
