// © 2021 Tfarraj
import Config from '@/config';
import UserService from '@/services/UserService';
import ChannelService from '../../services/ChannelService';
import SubscriptionService from '@/services/SubscriptionService';

const state = {
  url: Config.apiUrl,
  token: localStorage.getItem('token') || null,
  chatToken: localStorage.getItem('chatToken') || null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  isAuthenticated: localStorage.getItem('token') || false,
  isUserActive: JSON.parse(localStorage.getItem('user'))?.status === 'active',
  channel: JSON.parse(localStorage.getItem('channel')) || null,
  subscribedChannels:
    JSON.parse(localStorage.getItem('subscribedChannels')) || [],
  channelPhoto: JSON.parse(localStorage.getItem('channelPhoto')) || null,
  streamKey: localStorage.getItem('streamKey') || '',
  displayLanguage: localStorage.getItem('displayLanguage') || 'en',
};

const getters = {
  getUrl: (state) => state.url,
  getIsAuthenticated: (state) => state.isAuthenticated,
  getIsUserActive: (state) => state.isUserActive,
  getToken: (state) => state.token,
  getChatToken: (state) => state.chatToken,
  getUser: (state) => state.user,
  getChannel: (state) => state.channel,
  getChannelPhoto: (state) => state.channelPhoto,
  getStreamKey: (state) => state.streamKey,
  getSubscribedChannels: (state) => state.subscribedChannels,
  getDisplayLanguage: (state) => state.displayLanguage,
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
    if (token) {
      state.isAuthenticated = true;
    } else {
      state.isAuthenticated = false;
    }
  },
  SET_CHAT_TOKEN(state, chatToken) {
    state.chatToken = chatToken;
  },
  SET_USER_DATA(state, data) {
    state.user = data;
  },
  SET_CHANNEL_DATA(state, data) {
    state.channel = data;
  },
  SET_SUBSCRIBED_CHANNEL_DATA(state, data) {
    state.subscribedChannels = data;
  },
  SET_CHANNEL_PHOTO(state, data) {
    state.channelPhoto = data;
  },
  SET_STREAM_KEY(state, data) {
    state.streamKey = data;
  },
  SET_DISPLAY_LANGUAGE(state, data) {
    state.displayLanguage = data;
  },
  CLEAR_AUTH(state) {
    state.token = null;
    state.chatToken = null;
    state.user = null;
    state.isAuthenticated = false;
    state.channel = null;
    state.streamKey = null;
    state.subscribedChannels = null;
  },
};

const actions = {
  signUp({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      UserService.signUp(credentials)
        .then(({ data }) => {
          commit('SET_TOKEN', data.token);
          commit('SET_CHAT_TOKEN', data.chatToken);
          localStorage.setItem('token', data.token);
          localStorage.setItem('chatToken', data.chatToken);
          resolve(data.token);
        })
        .catch((err) => reject(err));
    });
  },
  signIn({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      UserService.signIn(credentials)
        .then(({ data }) => {
          localStorage.setItem('token', data.token);
          localStorage.setItem('chatToken', data.chatToken);
          commit('SET_TOKEN', data.token);
          commit('SET_CHAT_TOKEN', data.chatToken);
          resolve(data.token);
        })
        .catch((err) => reject(err));
    });
  },
  retrieveChannel({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ChannelService.getChannels()
        .then(({ data }) => {
          // data is a list of channels. now we support only one
          const firstChannel = data[0];
          localStorage.setItem('channel', JSON.stringify(firstChannel));
          commit('SET_CHANNEL_DATA', firstChannel);
          if (firstChannel.photo) {
            dispatch('retrieveChannelPhoto', firstChannel.id);
          }
          dispatch('retrieveStreamKey', firstChannel.id);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  retrieveSubscribedChannels({ commit }) {
    if (state.isUserActive) {
      return new Promise((resolve, reject) => {
        SubscriptionService.getSubscribedChannels()
          .then(({ data }) => {
            localStorage.setItem('subscribedChannels', JSON.stringify(data));
            commit('SET_SUBSCRIBED_CHANNEL_DATA', data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    return;
  },
  retrieveUser({ commit }) {
    const token = localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      UserService.me(token)
        .then(({ data }) => {
          localStorage.setItem('user', JSON.stringify(data));
          state.isUserActive = data.status === 'active';
          commit('SET_USER_DATA', data);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  async signOut({ commit }) {
    await UserService.signOut({ chatToken: state.chatToken });
    localStorage.removeItem('token');
    localStorage.removeItem('chatToken');
    localStorage.removeItem('user');
    localStorage.removeItem('channel');
    localStorage.removeItem('subscribedChannels');
    localStorage.removeItem('channelPhoto');
    localStorage.removeItem('streamKey');
    commit('CLEAR_AUTH');
  },
  async removeMyUser({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.removeMyUser()
        .then(({ data }) => {
          localStorage.removeItem('token');
          localStorage.removeItem('chatToken');
          localStorage.removeItem('user');
          localStorage.removeItem('channel');
          localStorage.removeItem('subscribedChannels');
          localStorage.removeItem('channelPhoto');
          commit('CLEAR_AUTH');
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  },
  retrieveChannelPhoto({ commit }, channelId) {
    ChannelService.getChannelPhoto(channelId).then((data) => {
      commit('SET_CHANNEL_PHOTO', data);
      localStorage.setItem('channelPhoto', JSON.stringify(data));
    });
  },
  retrieveStreamKey({ commit }, channelId) {
    ChannelService.getStreamKey(channelId).then(({ data }) => {
      commit('SET_STREAM_KEY', data.streamKey);
      localStorage.setItem('streamKey', data.streamKey);
    });
  },
  generateStreamKey({ commit }, channelId) {
    return new Promise((resolve, reject) => {
      ChannelService.generateStreamKey(channelId)
        .then(({ data }) => {
          localStorage.setItem('streamKey', JSON.stringify(data.streamKey));
          commit('SET_STREAM_KEY', data.streamKey);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  removeStreamKey({ commit }, channelId) {
    return new Promise((resolve, reject) => {
      ChannelService.removeStreamKey(channelId)
        .then(({ data }) => {
          localStorage.removeItem('streamKey');
          commit('SET_STREAM_KEY', null);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  updateChatToken({ commit }, chatToken) {
    localStorage.setItem('chatToken', chatToken);
    commit('SET_CHAT_TOKEN', chatToken);
  },
  updateDisplayLanguage({ commit }, displayLanguage) {
    localStorage.setItem('displayLanguage', displayLanguage);
    commit('SET_DISPLAY_LANGUAGE', displayLanguage);
  },
  streamFormSubmit({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      UserService.updateMe(data)
        .then(({ data }) => {
          dispatch('retrieveUser');
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
