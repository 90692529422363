import { mapGetters } from 'vuex';
import { Settings } from 'luxon';

export default {
  data() {
    return {
      currentPreferredLanguage: 'en',
    };
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    setLocale() {
      const browserLanguage = navigator.language.split('-')[0];
      if (this.getUser) {
        this.currentPreferredLanguage = this.getUser.preferredLanguage;
      } else if (browserLanguage === 'ar') {
        this.currentPreferredLanguage = browserLanguage;
      }
      this.$i18n.locale = this.currentPreferredLanguage;

      this.$store.dispatch(
        'updateDisplayLanguage',
        this.currentPreferredLanguage,
      );
      if (this.currentPreferredLanguage === 'ar') this.$vuetify.rtl = true;
      else if (this.currentPreferredLanguage === 'en')
        this.$vuetify.rtl = false;
      Settings.defaultLocale = this.currentPreferredLanguage;
    },
  },
};
