export default {
  methods: {
    async signOut() {
      await this.$store.dispatch('signOut');
      this.resetUserData();
    },
    async removeMyUser() {
      try {
        var data = await this.$store.dispatch('removeMyUser');
        this.resetUserData();
        return data;
      } catch (error) {
        return error;
      }
    },
    resetUserData() {
      this.setLocale();
      this.$router.push('/');
      // hide the seprator above subscribion in navigationItems menu
      this.navigationItems[1].header = false;
      // hide subscriptions navigationItems
      this.navigationItems[0].pages.pop();
      // hide liked videos navigationItems
      this.navigationItems[1].pages.pop();
      // hide history navigationItems
      this.navigationItems[1].pages.pop();
      this.subscriptionsItems[0].pages = [];
    },
  },
};
