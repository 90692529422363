export default {
  data() {
    return {
      channelVisibility: [
        { key: 'public', value: 'public' },
        { key: 'private', value: 'private' },
      ],
      videoVisibility: [
        { key: 'public', value: 'public' },
        { key: 'private', value: 'private' },
        { key: 'unlisted', value: 'unlisted' },
      ],
      duration: [
        { key: 'short', value: 'short' },
        { key: 'medium', value: 'medium' },
        { key: 'long', value: 'long' },
      ],
      orientation: [
        { key: 'portrait', value: 'portrait' },
        { key: 'landscape', value: 'landscape' },
      ],
      stage: [
        { key: 'live', value: 'live' },
        { key: 'encoding', value: 'encoding' },
        { key: 'vod', value: 'vod' },
      ],
      draft: [
        { key: 'true', value: 'draft' },
        { key: 'false', value: 'published' },
      ],
      banned: [
        { key: 'true', value: 'banned' },
        { key: 'false', value: 'not banned' },
      ],
    };
  },
};
