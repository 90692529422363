// © 2021 Tfarraj
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {
  required,
  email,
  max,
  min,
  size,
  oneOf,
} from 'vee-validate/dist/rules';
import VueFinalModal from 'vue-final-modal';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import Vuebar from 'vuebar';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import './assets/css/main.css';
import VueSocialSharing from 'vue-social-sharing';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import VueSnip from 'vue-snip';
import i18n from '@/i18n';

library.add(faCoffee);

// import InfiniteLoading from 'vue-infinite-loading'
//process.env.MAX_FILE_UPLOAD = 20000000;
setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Enter {_field_}',
});

extend('oneOf', {
  ...oneOf,
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters',
});

extend('email', {
  ...email,
  message: 'Email must be valid',
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'passwordDoesNotMatch',
});

extend('size', {
  ...size,
  message: 'video size should be less than 20 GB!',
});

Vue.config.productionTip = false;
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Vue.use(InfiniteLoading, {
//   props: {
//     distance: null
//     /* other props need to configure */
//   }
// })
Vue.component('font-awesome-icon', FontAwesomeIcon);
// Vue.component('InfiniteLoading', InfiniteLoading)
Vue.use(VueFinalModal());
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(VueSocialSharing);
Vue.use(Vuebar);
Vue.use(VueSnip);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
