<template>
  <center>
    <v-alert prominent type="error">
      <v-row align="center">
        <v-col class="">
          <div class="">
            <h1>{{ $t('notFound.PageNotFound') }}</h1>
          </div>
          <div>
            {{ $t('notFound.noPageExists') }}
          </div>
          <v-col class="shrink">
            <v-btn @click="$router.push('/')">
              {{ $t('notFound.goHome') }}
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-alert>
  </center>
</template>
<script>
import router from '../router';

export default {
  name: 'NotFound',
};
</script>
<style scoped>
center {
  margin: 15vw;
}
h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
