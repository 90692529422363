<!-- © 2021 Tfarraj -->
<template>
  <nav id="navbar">
    <v-app-bar
      class="black"
      flat
      app
      v-bind="{ [`clipped-${$vuetify.rtl ? 'right' : 'left'}`]: true }"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        ><router-link to="/"
          ><img
            class="responsive-img"
            src="/logo.svg"
            :alt="siteName" /></router-link
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        flat
        hide-details
        append-icon="mdi-magnify"
        v-bind:placeholder="$t('navbar.search')"
        outlined
        dense
        v-model="searchText"
        @click:append="search"
        @keypress.enter="search"
      ></v-text-field>

      <v-spacer></v-spacer>
      <v-btn
        width="10px"
        class="mr-8 ml-8 createVideoBtn"
        v-if="!$store.getters.getIsAuthenticated"
        router
        to="/signin"
      >
        <v-icon class="red--text small" size="25">mdi-video-plus</v-icon>
      </v-btn>

      <v-menu offsetY v-else>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                width="10px"
                class="mr-8 ml-8 createVideoBtn"
                v-on="{ ...tooltip, ...menu }"
                @click="modal"
                ><v-icon size="25" class="red--text small"
                  >mdi-video-plus</v-icon
                ></v-btn
              >
            </template>
            <span>{{ $t('navbar.createVideo') }}</span>
          </v-tooltip>
        </template>
      </v-menu>
      <!-- <v-menu offsetY v-else>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                width="10px"
                class="mr-8 createVideoBtn"
                v-on="{ ...tooltip, ...menu }"
                ><v-icon class="red--text small" size="25"
                  >mdi-video-plus</v-icon
                ></v-btn
              >
            </template>
            <span>Create a video and more</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item router to="/studio">
            <v-list-item-icon class="mr-3"
              ><v-icon>mdi-play-box-outline</v-icon></v-list-item-icon
            >
            <v-list-item-title>Upload Video</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/studio">
            <v-list-item-icon class="mr-3"
              ><v-icon>mdi-access-point</v-icon></v-list-item-icon
            >
            <v-list-item-title>Go live</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on"> <v-icon size="25">mdi-apps</v-icon></v-btn>
        </template>
        <span>{{ siteName }} apps</span>
      </v-tooltip> -->

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-7">
            <v-icon size="25">mdi-bell</v-icon></v-btn
          >
        </template>
        <span>Notifications</span>
      </v-tooltip> -->
      <v-btn
        tile
        color="#fffff"
        v-if="!$store.getters.getIsAuthenticated"
        router
        to="/signin"
      >
        <v-icon center size="26">mdi-account-circle</v-icon>
      </v-btn>

      <v-menu offset-y left v-else>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            color="indigo darken-4"
            depressed
            fab
            v-on="on"
            class="white--text"
          >
            <v-avatar v-if="getChannel.photo">
              <img-with-auth :id="getChannel.id" :type="'channel'" />
            </v-avatar>
            <template v-else>
              <span class="headline">{{
                getChannel.title.split('')[0].toUpperCase()
              }}</span>
            </template>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar v-if="getChannel.photo">
                  <img-with-auth :id="getChannel.id" :type="'channel'" />
                </v-avatar>
                <template v-else>
                  <v-avatar color="indigo darken-4">
                    <span class="white--text headline">{{
                      getChannel.title.split('')[0].toUpperCase()
                    }}</span>
                  </v-avatar>
                </template>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{
                  getChannel.title
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              router
              :to="`/channels/${$store.getters.getChannel.id}`"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('navbar.channel') }}</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/studio">
              <v-list-item-icon>
                <v-icon>mdi-youtube-studio</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('navbar.create') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-icon>
                <v-icon>mdi-login-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('navbar.signout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :right="$vuetify.rtl"
      app
      :clipped="$route.name !== 'Watch'"
      :temporary="$route.name === 'Watch'"
      id="nav"
    >
      <div tag="div" class="v-navigation-drawer__content" v-bar>
        <v-list dense nav class="py-0" tag="div">
          <v-list-item
            :class="{
              'hidden-lg-and-up': $route.name === 'Watch' ? false : true,
            }"
          >
            <v-app-bar-nav-icon
              @click="drawer = !drawer"
              class="mr-5"
            ></v-app-bar-nav-icon>
            <v-toolbar-title
              ><img src="/logo.svg" :alt="siteName" height="35"
            /></v-toolbar-title>
          </v-list-item>
          <v-divider class="hidden-lg-and-up"></v-divider>
          <div v-for="parentItem in navigationItems" :key="parentItem.header">
            <v-subheader
              v-if="parentItem.header"
              class="pl-3 py-4 subtitle-1 font-weight-bold"
              >{{ $t('navbar.' + parentItem.header) }}</v-subheader
            >
            <v-list-item
              v-for="(item, i) in parentItem.header === 'subscriptions'
                ? navigationItems[2].pages.slice(0, channelLength)
                : parentItem.pages"
              :key="item.title"
              class="mb-0"
              @click="conditionalRouter(parentItem, item)"
              exact
              active-class="active-item"
            >
              <v-list-item-icon v-if="parentItem.header !== 'subscriptions'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-avatar v-else class="mr-5">
                {{ i }}
                <v-avatar v-if="item.photo">
                  <img-with-auth :id="item.id" :type="'channel'" />
                </v-avatar>
                <template v-else>
                  <v-avatar color="indigo darken-4"
                    ><span class="white--text pr-2">{{
                      item.title.split('')[0].toUpperCase()
                    }}</span>
                  </v-avatar>
                </template>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-medium subtitle-2">{{
                  parentItem.header === 'Subscriptions'
                    ? item.channelId.channelName
                    : $t('navbar.' + item.title)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-btn
              id="showBtn"
              @click="moreChannels"
              v-if="
                parentItem.header === 'subscriptions' &&
                getIsAuthenticated &&
                navigationItems[2].pages.length > 3
              "
              block
              text
              class="white--text"
            >
              <v-icon>{{
                channelLength === 3 ? 'mdi-chevron-down' : 'mdi-chevron-up'
              }}</v-icon>
              {{
                channelLength === 3
                  ? `Show ${navigationItems[2].pages.length - 3} more `
                  : 'Show less'
              }}</v-btn
            >

            <v-divider
              v-if="parentItem.header !== false"
              class="mt-2 mb-2"
            ></v-divider>
          </div>
          <div
            v-for="parentItem in subscriptionsItems"
            :key="parentItem.header"
          >
            <v-subheader
              v-if="parentItem.header"
              class="pl-3 py-4 subtitle-1 font-weight-bold"
              >{{ $t('navbar.' + parentItem.header) }}</v-subheader
            >
            <v-list-item
              v-for="(item, i) in parentItem.header === 'subscriptions' &&
              getSubscribedChannels
                ? getSubscribedChannels.slice(0, channelLength)
                : parentItem.pages"
              :key="item.title"
              class="mb-0"
              :to="
                parentItem.header === 'subscriptions'
                  ? '/channels/' + item.id
                  : item.link
              "
              exact
              active-class="active-item"
            >
              <v-list-item-icon v-if="parentItem.header !== 'subscriptions'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-avatar v-else class="mr-5">
                {{ i }}
                <v-avatar v-if="item.photo">
                  <img-with-auth :id="item.id" :type="'channel'" />
                </v-avatar>
                <template v-else>
                  <v-avatar color="indigo darken-4"
                    ><span class="white--text pr-2">{{
                      item.title.split('')[0].toUpperCase()
                    }}</span>
                  </v-avatar>
                </template>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-medium subtitle-2">{{
                  parentItem.header === 'Subscriptions'
                    ? item.channelId.channelName
                    : item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-btn
              id="showBtn"
              @click="moreChannels"
              v-if="
                parentItem.header === 'subscriptions' &&
                getIsAuthenticated &&
                getSubscribedChannels &&
                getSubscribedChannels.length > 3
              "
              block
              text
              class="white--text"
            >
              <v-icon>{{
                channelLength === 3 ? 'mdi-chevron-down' : 'mdi-chevron-up'
              }}</v-icon>
              {{
                channelLength === 3 && getSubscribedChannels
                  ? `Show ${getSubscribedChannels.length - 3} more `
                  : 'Show less'
              }}</v-btn
            >

            <v-divider
              v-if="parentItem.header !== false"
              class="mt-2 mb-2"
            ></v-divider>
          </div>
          <span v-for="link in links" :key="link.text">
            <span v-if="link.text === 'Terms'" class="mb-2 d-block"> </span>
            <v-btn
              href
              router
              :to="`/terms-${getDisplayLanguage}.html`"
              target="_blank"
              text
              class="px-1"
              small
              >{{ $t('navbar.' + link.text) }}</v-btn
            >
          </span>
        </v-list>
      </div>
    </v-navigation-drawer>
    <upload-video-modal
      v-if="$store.getters.getIsAuthenticated"
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import UploadVideoModal from '@/modals/UploadVideoModal';
import ImgWithAuth from '@/components/ImgWithAuth';
import LocalizationMixin from '@/mixins/LocalizationMixin.ts';
import AuthMixin from '@/mixins/AuthMixin.ts';
import DialogModal from '@/modals/DialogModal';

export default {
  mixins: [LocalizationMixin, AuthMixin],
  data() {
    return {
      siteName: Config.siteName,
      drawer: true,
      showDialog: false,
      details: {},
      navigationItems: [
        {
          header: null,
          pages: [
            { title: 'home', link: '/', icon: 'mdi-home' },
            { title: 'trending', link: '/trending', icon: 'mdi-fire' },
            {
              title: 'subscriptions',
              link: '/subscriptions',
              icon: 'mdi-youtube-subscription',
            },
          ],
        },
        {
          header: null,
          pages: [
            {
              title: 'history',
              link: '/history',
              icon: 'mdi-history',
            },

            {
              title: 'likedVideos',
              link: '/liked-videos',
              icon: 'mdi-thumb-up',
            },
          ],
        },
      ],
      subscriptionsItems: [
        {
          header: 'subscriptions',
          pages: [],
        },
      ],
      links: [
        {
          text: 'terms',
          link: '/terms-' + this.getDisplayLanguage + '.html',
        },
      ],
      channelLength: 0,
      searchText: '',
      dialog: false,
      // user: null
    };
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUrl',
      'getIsAuthenticated',
      'getIsUserActive',
      'getChannel',
      'getSubscribedChannels',
      'getDisplayLanguage',
    ]),
  },
  methods: {
    conditionalRouter(parentItem, item) {
      if (parentItem.header === 'subscriptions') {
        this.$router.push(`/channels/${item.id}`);
      } else if (item.title === 'likedVideos' && !this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
      } else this.$router.push(item.link);
    },
    modal() {
      if (this.getIsUserActive) {
        this.dialog = true;
      } else {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
      }
    },
    async search() {
      if (!this.searchText) {
        this.$router.push('/');
        return;
      }
      if (this.searchText === this.$route.query['search-query']) return;
      // this.searchText = this.$route.query['search-query']
      const data = {
        type: 'search',
        searchText: this.searchText,
      };

      this.$router.push({
        name: 'Search',
        query: { 'search-query': this.searchText },
      });
    },
    moreChannels() {
      if (this.channelLength === 3) {
        this.channelLength = this.getSubscribedChannels.length;
      } else this.channelLength = 3;
    },
    fetchSubscribedChannels() {
      const subscribedChannels = this.getSubscribedChannels;
      if (subscribedChannels === null) return;
      this.subscriptionsItems[0].pages = subscribedChannels;
      this.moreChannels();
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   this.searchText = ''
  //   next()
  // },
  // beforeRouteUpdate(to, from, next) {
  //   if (!to.query['search-query'] === '') return
  //   this.searchText = to.query['search-query']
  //   next()
  // },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!to.query['search-query'] === '') return;
      vm.searchText = to.query['search-query'];
    });
  },
  components: {
    UploadVideoModal,
    DialogModal,
    ImgWithAuth,
  },
  mounted() {
    if (this.getUser) this.fetchSubscribedChannels();
    this.drawer = !this.$vuetify.breakpoint.mdAndDown;
    this.drawer = this.$route.name === 'Watch' ? false : this.drawer;
  },
  created() {
    this.drawer = this.$route.name === 'Watch' ? false : this.drawer;

    if (!this.getIsAuthenticated) {
      // hide the seprator above subscribion in navigationItems menu
      this.navigationItems[1].header = false;
      // hide subscriptions navigationItems
      this.navigationItems[0].pages.pop();
      // hide liked videos navigationItems
      this.navigationItems[1].pages.pop();
      // hide history navigationItems
      this.navigationItems[1].pages.pop();
    }
  },
};
</script>

<style lang="scss">
.v-list-item__avatar {
  justify-content: center !important;
}
#showBtn {
  .v-btn__content {
    justify-content: flex-start;

    i {
      margin-right: 30px;
    }
  }
}
#navbar {
  .active-item {
    .v-list-item__icon {
      color: indigo darken-4 !important;
    }
  }
  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
    background: #dfe9fe;
  }

  .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
    width: 10px;
    background-color: #e0e0e0;
  }

  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
  .createVideoBtn {
    border-color: rgb(185, 181, 181);
  }
}
</style>
<style lang="scss" scoped>
.responsive-img {
  max-width: 100%;
  height: 50px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>
