// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  getChannels() {
    return Api().get('channels');
  },
  getChannelById(channelId) {
    return Api().get(`channels/${channelId}`);
  },
  getChannelPhoto(channelId) {
    return Api()
      .get(`channels/${channelId}/photo`, { responseType: 'arraybuffer' })
      .then((res) => {
        const base64String = Buffer.from(res.data).toString('base64');
        return `data:image/jpg;base64,${base64String}`;
      })
      .catch((err) => {});
  },
  updateChannelPhoto(channelId, data) {
    return Api().put(`channels/${channelId}/photo`, data);
  },
  getStreamKey(channelId) {
    return Api().get(`channels/${channelId}/stream-key`);
  },
  generateStreamKey(channelId) {
    return Api().post(`channels/${channelId}/stream-key`);
  },
  removeStreamKey(channelId) {
    return Api().delete(`channels/${channelId}/stream-key`);
  },
  addMember(channelId, data) {
    return Api().post(`channels/${channelId}/members`, data);
  },
  getMembers(channelId) {
    return Api().get(`channels/${channelId}/members`);
  },
  updateMember(channelId, memberId, data) {
    return Api().put(`channels/${channelId}/members/${memberId}`, data);
  },
  removeMember(channelId, memberId) {
    return Api().delete(`channels/${channelId}/members/${memberId}`);
  },
  deleteById(channelId) {
    return Api().delete(`channels/${channelId}`);
  },
  getStudioVideos(channelId, params) {
    return Api().get(`channels/${channelId}/studio`, {
      params,
    });
  },
  reportChannel(data) {
    return Api().post(`channels/${data.channelId}/reports`, data);
  },
  blockChannel(blockerId, blockedId) {
    return Api().put(`channels/${blockerId}/blocked/${blockedId}`);
  },
  unBlockChannel(blockerId, blockedId) {
    return Api().delete(`channels/${blockerId}/blocked/${blockedId}`);
  },
};
