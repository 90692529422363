<!-- © 2021 Tfarraj -->
<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="fab-transition"
      max-width="1000"
    >
      <v-card>
        <div id="modal-header" class="d-flex justify-space-between mb-5">
          <v-card-title class="py-3">
            {{ $t('uploadVideoModal.uploadVideo') }}
          </v-card-title>
          <div class="mt-3 mr-2">
            <v-btn icon text @click="closeModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <v-card-text
          v-if="!uploaded"
          class="
            d-flex
            flex-column
            align-center
            my-md-12
            py-md-12
            my-sm-8
            py-sm-8
            my-xs-0
            py-xs-0
            my-12
            py-12
          "
        >
          <div v-if="!uploading" class="text-center">
            <div>
              <v-btn
                icon
                class="grey lighten-2 mb-4"
                style="height: 104px; width: 104px"
                @click="selectFile"
              >
                <v-icon x-large class="grey--text text--darken-1">
                  mdi-upload
                </v-icon>
              </v-btn>
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              ref="provider"
              rules="required|size:5159367048"
              name="file"
            >
              <v-file-input
                ref="fileInput"
                v-model="selectedFile"
                style="display: none"
                placeholder="Choose Video"
                accept="video/*"
                :error-messages="
                  errors != '' ? $t('uploadVideoModal.' + errors) : ''
                "
                @change="uploadFile"
              />
            </ValidationProvider>
            <v-btn
              type="submit"
              depressed
              class="blue darken-3 flat white--text mt-4"
              @click="$refs.fileInput.$refs.input.click()"
            >
              {{ $t('uploadVideoModal.selectVideo') }}
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <h2 class="mb-6">
            {{ $t('uploadVideoModal.details') }}
          </h2>
          <v-row v-if="!uploading">
            <v-col
              order="last"
              order-sm="last"
              order-md="first"
              order-lg="first"
              order-xl="first"
              cols="12"
              sm="12"
              md="8"
              lg="8"
            >
              <ValidationObserver
                ref="uploadVideoForm"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  @submit.prevent="handleSubmit(submit)"
                  @reset.prevent="reset"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required|min:3"
                  >
                    <v-text-field
                      ref="inputRef"
                      v-model="formData.title"
                      :error-messages="
                        errors != '' ? $t('uploadVideoModal.' + errors) : ''
                      "
                      :label="$t('uploadVideoModal.title')"
                      class="mb-3"
                      filled
                      dense
                      clearable
                    >
                      counter="100" max-length="100" >
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="Description">
                    <v-textarea
                      v-model="formData.description"
                      filled
                      auto-grow
                      :error-messages="
                        errors != '' ? $t('uploadVideoModal.' + errors) : ''
                      "
                      :label="$t('uploadVideoModal.description')"
                      :placeholder="
                        $t('uploadVideoModal.TellViewersAboutYourVideo')
                      "
                      rows="5"
                      counter="5000"
                      max-length="5000"
                      row-height="20"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Visibility"
                    rules="required"
                  >
                    <v-select
                      v-model="formData.visibility"
                      :items="videoVisibility"
                      :error-messages="
                        errors != '' ? $t('uploadVideoModal.' + errors) : ''
                      "
                      filled
                      :label="$t('uploadVideoModal.visibility')"
                      item-value="key"
                      item-text="value"
                    >
                      <template #selection="{ item }">
                        {{ $t('visibility.' + item.value) }}
                      </template>
                      <template #item="{ item }">
                        {{ $t('visibility.' + item.value) }}
                      </template>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Language"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.contentLanguage"
                      :items="contentLanguage"
                      item-text="value"
                      item-value="key"
                      :error-messages="
                        errors != '' ? $t('uploadVideoModal.' + errors) : ''
                      "
                      filled
                      :label="$t('uploadVideoModal.language')"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.category"
                      :items="categories"
                      item-text="value"
                      item-value="key"
                      :error-messages="
                        errors != '' ? $t('uploadVideoModal.' + errors) : ''
                      "
                      filled
                      :label="$t('uploadVideoModal.categories')"
                      :loading="categoryLoading"
                    />
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors }" name="Tags">
                    <v-combobox
                      multiple
                      v-model="formData.tags"
                      :label="$t('uploadVideoModal.tags')"
                      append-icon
                      chips
                      :delimiters="[',']"
                      deletable-chips
                      :search-input.sync="tags"
                      @update:search-input="updateTags"
                    />
                  </ValidationProvider>
                  <div class="mt-6 d-flex justify-space-between">
                    <v-btn
                      :loading="submitLoading"
                      type="submit"
                      class="primary"
                      depressed
                    >
                      {{ $t('uploadVideoModal.submit') }}
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </v-col>
            <!-- <v-col
              order-sm="1"
              cols="12"
              sm="12"
              md="4"
              lg="4"
              class="text-center"
            >
              <v-btn @click="toggleShow">{{ $t('uploadVideoModal.uploadThumbnail') }}</v-btn>
              <my-upload
                field="thumbnail"
                @crop-success="cropSuccess"
                method="PUT"
                v-model="show"
                :width="640"
                :height="360"
                :url="url"
                :headers="headers"
                img-format="jpg"
                langType="en"
              ></my-upload>
              <v-responsive width="330" class="mx-auto">
                <div v-if="!imgDataUrl" class="px-12" id="image-placeholder">
                  <v-icon>mdi-image-plus</v-icon>
                </div>
                <v-img v-else width="300vh" :src="imgDataUrl"></v-img>
              </v-responsive>
            </v-col> -->
          </v-row>
          <v-progress-circular
            v-else
            :rotate="360"
            :size="100"
            :width="15"
            :value="value"
            color="teal"
          >
            {{ value + '%' }}
          </v-progress-circular>
        </v-card-text>
        <v-card-actions v-if="!uploaded">
          <p class="text-center grey--text caption px-12 px-xs-0">
            {{ $t('uploadVideoModal.uploadTerms1') }} {{ siteName
            }}{{ $t('uploadVideoModal.uploadTerms2') }}{{ siteName
            }}{{ $t('uploadVideoModal.uploadTerms3') }}
          </p>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top timeout="-1">
      <v-icon left color="error"> mdi-alert-circle </v-icon>
      {{ $i18n.t('video.uploadError') }}
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          CLOSE
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { mapGetters } from 'vuex';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import Config from '@/config';
import CategoryService from '@/services/CategoryService';
import LocalizationMixin from '@/mixins/LocalizationMixin.ts';
import FormsMixin from '@/mixins/FormsMixin.ts';
import CommonMixin from '@/mixins/CommonMixin.ts';

export default {
  name: 'UploadModal',
  mixins: [LocalizationMixin, FormsMixin, CommonMixin],
  props: ['openDialog'],
  data() {
    return {
      uppy: null,
      siteName: Config.siteName,
      tusdUrl: Config.tusdUrl,
      // dialog: this.openDialog ? this.openDialog : false,
      valid: false,
      uploaded: false,
      uploading: false,
      submitLoading: false,
      categoryLoading: false,
      interval: {},
      value: 0,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size > 5159367048 ||
          `Video size should be less than 5 GB!, ${value.size}`,
      ],
      categoriesData: [],
      categories: [],
      tags: '',
      contentLanguage: [
        { key: 'ar', value: 'Arabic' },
        { key: 'da', value: 'Danish' },
        { key: 'nl', value: 'Dutch' },
        { key: 'en', value: 'English' },
        { key: 'fi', value: 'Finnish' },
        { key: 'fr', value: 'French' },
        { key: 'de', value: 'German' },
        { key: 'hu', value: 'Hungarian' },
        { key: 'it', value: 'Italian' },
        { key: 'no', value: 'Norwegian' },
        { key: 'pt', value: 'Portuguese' },
        { key: 'ro', value: 'Romanian' },
        { key: 'ru', value: 'Russian' },
        { key: 'es', value: 'Spanish' },
        { key: 'sv', value: 'Swedish' },
        { key: 'tr', value: 'Turkish' },
      ],
      selectedFile: [],
      formData: {
        id: '',
        title: '',
        description: '',
        category: '',
        contentLanguage: '',
        visibility: '',
        tags: [],
      },
      imgDataUrl: '',
      imgDataObj: [],
      url: '',
      headers: { Authorization: `Bearer ${this.$store.getters.getToken}` },
      snackbar: false,
    };
  },
  computed: {
    dialog() {
      return this.openDialog;
    },
    ...mapGetters(['getChannel', 'getUser', 'getDisplayLanguage']),
  },
  watch: {
    getDisplayLanguage() {
      this.getCategories();
      this.formData.contentLanguage = this.getDisplayLanguage;
    },
  },
  async mounted() {
    await this.uppyInit();
    this.getCategories();
    this.formData.contentLanguage = this.getDisplayLanguage;
  },
  methods: {
    async uploadFile(e) {
      const { valid } = await this.$refs.provider.validate(e);

      if (!valid) return;

      this.uploaded = true;
      this.uploading = false;
    },
    async submit() {
      this.uploading = true;
      const metadata = {
        title: this.formData.title,
        description: this.formData.description,
        channel: this.getChannel.id,
        contentLanguage: this.formData.contentLanguage,
        category: this.formData.category,
        tags: JSON.stringify(this.formData.tags),
        visibility: this.formData.visibility,
        draft: false,
      };
      this.submitLoading = true;

      this.uppy.addFile(this.selectedFile);
      this.uppy.setMeta(metadata);
      await this.uppy.upload();

      this.submitLoading = false;
      this.uploading = false;
      this.uploaded = false;

      this.closeModal();
    },
    async getCategories() {
      this.categoryLoading = true;
      const categoriesResponse = await CategoryService.getAll()
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => (this.categoryLoading = false));
      this.categoriesData = categoriesResponse.data.data;
      this.updateCategoriesLanguage();
    },
    updateCategoriesLanguage() {
      this.categories = this.categoriesData.map((category) => ({
        key: category._id,
        value:
          this.getDisplayLanguage === 'en' ? category.nameEn : category.nameAr,
      }));
    },
    async resetForm() {
      this.imgDataUrl = '';
      this.selectedFile = [];
      this.value = 0;
      this.formData = {
        id: '',
        title: '',
        description: '',
        category: '',
        contentLanguage: this.getDisplayLanguage,
        visibility: '',
        tags: [],
      };
      await this.uppyInit();
    },
    closeModal() {
      this.$emit('closeDialog');
    },
    selectFile() {
      this.$refs.fileInput.$refs.input.click();
    },
    toggleShow() {
      this.show = !this.show;
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async uppyInit() {
      this.uppy = new Uppy({
        id: 'upp1y',
        autoProceed: false,
      }).use(Tus, {
        endpoint: this.tusdUrl,
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      });

      this.uppy.on('upload-error', (file, error) => {
        this.snackbar = true;
        this.resetForm();
      });

      this.uppy.on('upload-success', () => {
        this.resetForm();
        this.closeModal();
        this.$router.push('/studio/videos');
      });

      this.uppy.on('progress', (progress) => {
        this.value = progress;
      });
    },
    async cropSuccess(imgDataUrl, field) {
      const file = this.dataURLtoBlob(imgDataUrl);
      this.imgDataObj = file;
      this.imgDataUrl = imgDataUrl;
    },
  },
  components: {
    myUpload,
  },
};
</script>

<style lang="scss">
#modal-header {
  border-bottom: 1px solid rgb(238, 232, 232);
}

#image-placeholder {
  padding-top: 8em;
  padding-bottom: 8em;
  border: 2px dashed rgb(209, 209, 209);
}
/* .el-scrollbar__bar {
opacity: 1 !important;
} */
</style>
