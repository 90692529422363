<!-- © 2021 Tfarraj -->
<template>
  <nav id="studioBar">
    <v-app-bar
      class="black"
      app
      v-bind="{ [`clipped-${$vuetify.rtl ? 'right' : 'left'}`]: true }"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-bold"
        ><router-link
          to="/studio"
          class="black--text"
          style="text-decoration: none"
          ><img class="responsive-img" src="/studio-logo.svg" alt="Studio" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        flat
        hide-details
        append-icon="mdi-magnify"
        placeholder="Search"
        outlined
        dense
        v-model="searchText"
        @click:append="search"
        @keypress.enter="search"
        class="hidden-sm-and-down"
      ></v-text-field>
      <!-- <v-toolbar-title>Page title</v-toolbar-title> -->

      <v-spacer></v-spacer>
      <v-menu offsetY>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                width="10px"
                class="mr-8 ml-8 createVideoBtn"
                v-on="{ ...tooltip, ...menu }"
                @click="modal"
                ><v-icon size="25" class="red--text small"
                  >mdi-video-plus</v-icon
                ></v-btn
              >
            </template>
            <span>{{ $t('navbar.createVideo') }}</span>
          </v-tooltip>
        </template>
      </v-menu>

      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            color="indigo darken-4"
            depressed
            fab
            v-on="on"
            class="white--text"
          >
            <v-avatar v-if="getChannel.photo">
              <img-with-auth :id="getChannel.id" :type="'channel'" />
            </v-avatar>
            <template v-else>
              <span class="headline">
                {{ getChannel.title.split('')[0].toUpperCase() }}
              </span>
            </template>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar v-if="getChannel.photo">
                  <img-with-auth :id="getChannel.id" :type="'channel'" />
                </v-avatar>
                <template v-else>
                  <v-avatar color="indigo darken-4">
                    <span class="white--text headline">
                      {{ getChannel.title.split('')[0].toUpperCase() }}</span
                    >
                  </v-avatar>
                </template>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{
                  getChannel.title
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              router
              :to="`/channels/${$store.getters.getChannel.id}`"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t('studioNavBar.channel')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/studio">
              <v-list-item-icon>
                <v-icon>mdi-youtube-studio</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t('studioNavBar.create')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-icon>
                <v-icon>mdi-login-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t('studioNavBar.signout')
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      id="nav"
      :right="$vuetify.rtl"
    >
      <div tag="div" class="v-navigation-drawer__content" v-bar>
        <v-list tile class="py-0">
          <v-list-item
            v-for="item in items[0].pages"
            :key="item.title"
            href
            tile
            class="mb-0"
            router
            :to="item.link"
            exact
            active-class="active-item"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-medium subtitle-2">
                {{ $t('studioNavBar.' + item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <template v-slot:append>
        <div class="pa-1">
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              v-for="item in items[1].pages"
              :key="item.title"
              link
              class="mb-0"
              :to="item.link === '' ? '' : item.link"
              @click="settingsModal(item.title)"
              exact
              active-class="active-item"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-weight-medium subtitle-2">
                  {{ $t('studioNavBar.' + item.title) }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>
      <template v-slot:prepend>
        <div class="pa-1 text-center">
          <v-list class="text-center">
            <v-list-item link :to="`/channels/${getChannel.id}`" class="px-2">
              <v-btn
                height="95"
                width="95"
                x-large
                color="indigo darken-4"
                depressed
                fab
                class="white--text mx-auto"
              >
                <v-avatar height="96" width="96" v-if="getChannel.photo">
                  <img-with-auth :id="getChannel.id" :type="'channel'" />
                </v-avatar>
                <!-- <template > -->
                <span v-else class="display-3">
                  {{ getChannel.title.split('')[0].toUpperCase() }}
                </span>
                <!-- </template> -->
                <!-- <span class="display-3" v-if="getUser.photoUrl === 'no-photo.jpg'">
                     {{ getUser.channelName.split('')[0].toUpperCase() }}
                     </span> -->
              </v-btn>
            </v-list-item>

            <v-list-item link :to="`/channels/${getChannel.id}`">
              <v-list-item-content>
                <v-list-item-title class="title">{{
                  getChannel.title
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
    <settings-modal
      :open-dialog="settingsDialog"
      v-on:closeDialog="settingsDialog = false"
    />
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadVideoModal from '@/modals/UploadVideoModal';
import SettingsModal from '@/modals/SettingsModal';
import ImgWithAuth from '@/components/ImgWithAuth';
import LocalizationMixin from '@/mixins/LocalizationMixin.ts';
import AuthMixin from '@/mixins/AuthMixin.ts';
import DialogModal from '@/modals/DialogModal';

export default {
  name: 'StudioNavBar',
  mixins: [LocalizationMixin, AuthMixin],
  data() {
    return {
      searchText: '',
      drawer: false,
      showDialog: false,
      details: {},
      items: [
        {
          header: 'scroll',
          pages: [
            {
              title: 'backToHome',
              link: '/',
              icon: 'mdi-home',
            },
            { title: 'dashboard', link: '/studio', icon: 'mdi-view-dashboard' },
            {
              title: 'videos',
              link: '/studio/videos',
              icon: 'mdi-play-box-multiple',
            },
          ],
        },
        {
          header: 'fixed',
          pages: [
            {
              title: 'settings',
              link: '',
              icon: 'mdi-cog',
            },
          ],
        },
      ],
      dialog: false,
      settingsDialog: false,
    };
  },
  computed: {
    ...mapGetters(['getUser', 'getUrl', 'getChannel', 'getIsUserActive']),
  },
  methods: {
    async search() {
      const videosRoute = 'Video';
      const currentRoute = String(this.$route.name);
      if (currentRoute === videosRoute) {
        const data = await this.$store
          .dispatch('setStudioSearchText', this.searchText)
          .catch((err) => {
            // console.log(err);
          });
      } else {
        this.$router.push({
          name: 'Video',
          query: { 'search-query': this.searchText },
        });
      }
    },
    modal() {
      if (this.getIsUserActive) {
        this.dialog = true;
      } else {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
      }
    },
    settingsModal(title) {
      if (title !== 'settings') return;
      this.settingsDialog = true;
    },
  },
  components: {
    UploadVideoModal,
    SettingsModal,
    ImgWithAuth,
    DialogModal,
  },
  mounted() {
    this.drawer = !this.$vuetify.breakpoint.mdAndDown;
  },
};
</script>

<style lang="scss">
#studioBar {
  .active-item {
    // border-radius: 0 !important;
    border-left: 3px solid #cc0202;
    .v-list-item__icon,
    .subtitle-2 {
      color: indigo darken-4 !important;
    }
  }
  .createVideoBtn {
    border-color: rgb(185, 181, 181);
  }
  // .v-navigation-drawer__border {
  //   width: 0 !important;
  // }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
    background-color: #8d8a8a !important;
  }

  .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: margin 100ms ease-out, height 100ms ease-out;
    transition: margin 100ms ease-out, height 100ms ease-out;

    margin: 5px 5px 5px 0;

    height: calc(100% - 10px);
    display: block;
    width: 10px;
    background-color: #8d8a8a;
  }
  .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
    width: 10px;
    background-color: #8d8a8a;
  }
  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: #8d8a8a;
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    // margin: 0px;
    // width: 10px;
    background-color: #8d8a8a;
  }

  .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: #8d8a8a;
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: #8d8a8a;
  }
}
</style>
<style lang="scss" scoped>
.responsive-img {
  max-width: 100%;
  height: 50px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>
