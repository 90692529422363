// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  signIn(credentials) {
    return Api().post('users/login', credentials);
  },
  signUp(data) {
    return Api().post('users', data);
  },
  activate(data) {
    return Api().post('users/activate', data);
  },
  forgotPassword(data) {
    return Api().post('users/forgot-password', data);
  },
  resetPassword(data) {
    return Api().post('users/reset-password', data);
  },
  signOut(data) {
    return Api().post('users/logout', data);
  },
  confirmEmailUpdate(data) {
    return Api().post('users/confirm-email', data);
  },
  updatePassword(data) {
    return Api().put('users/me/password', data);
  },
  updateMe(data) {
    return Api().put('users/me', data);
  },
  me(token) {
    return Api().get('users/me', {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll(filters) {
    return Api().get(`users/?${filters}`);
  },
  getById(id) {
    return Api().get(`users/${id}`);
  },
  createUser(data) {
    return Api().post('users', data);
  },
  updateUser(data, id) {
    return Api().put(`users/${id}`, data);
  },
  deleteById(id) {
    return Api().delete(`users/${id}`);
  },
  resendActivationEmail() {
    return Api().post('users/resend-activation-email');
  },
  removeMyUser() {
    return Api().delete('users/me');
  },
};
