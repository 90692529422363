<!-- © 2021 Tfarraj -->
<template>
  <v-app>
    <router-view name="NavBar"></router-view>
    <router-view name="StudioNavBar"></router-view>
    <v-main
      :class="{
        'content-bg': ![
          'SignIn',
          'SignUp',
          'Dashboard',
          'Video',
          'Detail',
        ].includes(this.$route.name)
          ? true
          : false,
      }"
    >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.ts';
export default {
  name: 'App',
  mixins: [LocalizationMixin],
  created() {
    this.setLocale();
  },
};
</script>

<style lang="scss">
.v-tabs {
  background-color: black !important;
}
.container {
  background-color: black !important;
}
.container--fluid {
  background-color: black !important;
}
.content-bg {
  background-color: black !important;
}
.v-card {
  background-color: black !important;
}
.card {
  background: black !important;
}
.row {
  background: black !important;
}

.v-responsive {
  background: black !important;
}

.mx-xs-auto {
  background: black !important;
}
.col-sm-12 {
  background: black !important;
}
.fill-height {
  background: black !important;
}
.v-application--wrap {
  background: black !important;
}
.card-radiobox {
  background: black !important;
}
v-card--flat {
  background: black !important;
}
.v-input {
  background: black !important;
}
.v-sheet {
  background: black !important;
}
.v-skeleton-loader {
  background: black !important;
}
.card
  .v-card
  .v-card--flat
  .v-sheet
  .v-sheet--tile
  .theme--dark
  .channel-header {
  background: black !important;
}
.v-sheet {
  background: black !important;
}
/* .v-responsive__content {
  background: black !important;
} */
</style>
