export default {
  siteName: process.env.VUE_APP_SITE_NAME,
  domain: process.env.VUE_APP_DOMAIN,
  uiUrl: process.env.VUE_APP_UI_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  tusdUrl: process.env.VUE_APP_TUSD_URL,
  mediaUrl: process.env.VUE_APP_MEDIA_URL,
  encodeUrl: process.env.VUE_APP_ENCODE_URL,
  encodeUrls: process.env.VUE_APP_ENCODE_URLS.split(/\s+/),
  chatUrl: process.env.VUE_APP_CHAT_URL,
  advertisingUrl: process.env.VUE_APP_ADVERTISING_URL,
  twitterUser: process.env.VUE_APP_TWITTER_USER,
  i18nLocaleAr: process.env.VUE_APP_I18N_LOCALE_AR,
  i18nLocaleEn: process.env.VUE_APP_I18N_LOCALE_EN,
  i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
};
