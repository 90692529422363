<!-- © 2021 Tfarraj -->
<template>
  <v-dialog v-model="dialog" @click:outside="closeModal" width="400">
    <v-card class="pt-8">
      <v-card-text>
        <h2 class="mb-3">{{ details.title }}</h2>
        <p>{{ details.text }}</p>
        <p>{{ details.textNote }}</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="details.buttonText === 'signin'"
          color="indigo darken-4"
          to="/signin"
        >
          {{ $t('dialogModal.signin') }}
        </v-btn>
        <v-btn
          v-if="details.buttonText === 'close'"
          color="indigo darken-4"
          @click="resendActivationEmail"
          :disabled="resendActivationEmailStatusDisabled"
        >
          {{ resendActivationEmailStatusText }}
        </v-btn>
        <v-btn
          v-if="details.buttonText === 'close'"
          color="indigo darken-4"
          @click="closeModal"
        >
          {{ $t('dialogModal.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserService from '@/services/UserService';

export default {
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialog() {
      return this.openModal;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async resendActivationEmail() {
      this.loading = true;
      try {
        await UserService.resendActivationEmail();
        this.resendActivationEmailStatusText = this.$i18n.t(
          'dialogModal.resendActivationEmailSuccess',
        );
        this.resendActivationEmailStatusDisabled = true;
      } catch (e) {
        this.resendActivationEmailStatusText = this.$i18n.t(
          'dialogModal.resendActivationEmailFailed',
        );
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      resendActivationEmailStatusText: this.$i18n.t(
        'dialogModal.resendActivationEmail',
      ),
      resendActivationEmailStatusDisabled: false,
    };
  },
};
</script>
