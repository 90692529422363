// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  getSubscribedChannels() {
    return Api().get('channels/subscribed');
  },
  getSubscribers(limit, channel) {
    return Api().get(`channels/${channel}/subscriptions`, {
      params: {
        limit,
      },
    });
  },
  createSubscription(subscriberId, subscribedId) {
    return Api().put(`channels/${subscriberId}/subscriptions/${subscribedId}`);
  },
  removeSubscription(channel) {
    return Api().delete(`channels/${channel}/subscription`);
  },
  getSubscribedVideos(params) {
    return Api().get('videos/subscribed', { params });
  },
};
