// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  getCommentByVideoId(videoId) {
    return Api().get(`videos/${videoId}/comments`);
  },
  createComment(videoId, data) {
    return Api().post(`videos/${videoId}/comments`, data);
  },
  deleteById(id) {
    return Api().delete(`comments/${id}`);
  },
};
