<!-- © 2021 Tfarraj -->
<template>
  <img v-if="isCurrentChannel" v-bind:src="this.getChannelPhoto" />
  <v-img v-else contain aspect-ratio="1.7778" v-bind:src="imgUrl" />
</template>

<script>
import { mapGetters } from 'vuex';
import VideoService from '@/services/VideoService';
import ChannelService from '@/services/ChannelService';

export default {
  name: 'imgWithAuth',
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imgUrl: '',
      isCurrentChannel: null,
    };
  },
  computed: {
    ...mapGetters(['getChannelPhoto', 'getChannel']),
  },
  async mounted() {
    switch (this.type) {
      case 'video':
        this.imgUrl = await VideoService.getThumbnail(this.id);
        break;

      case 'channel':
        if (this.getChannel && this.id === this.getChannel.id) {
          this.isCurrentChannel = true;
        } else {
          this.imgUrl = await ChannelService.getChannelPhoto(this.id);
        }
        break;
      default:
    }
  },
};
</script>
