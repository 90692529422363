// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  getAll(params) {
    return Api().get('videos', { params });
  },
  getRecommended() {
    return Api().get('videos/recommended');
  },
  getById(id) {
    return Api().get(`videos/${id}`);
  },
  createVideo(data, optional) {
    return Api().post('videos', data, optional);
  },
  updateVideo(id, data) {
    return Api().put(`videos/${id}`, data);
  },
  uploadThumbnail(id, data) {
    return Api().put(`videos/${id}/thumbnail`, data);
  },
  getThumbnail(id) {
    return Api()
      .get(`videos/${id}/thumbnail`, { responseType: 'arraybuffer' })
      .then((res) => {
        const base64String = Buffer.from(res.data).toString('base64');
        return `data:image/jpg;base64,${base64String}`;
      })
      .catch((err) => {});
  },
  deleteById(id) {
    return Api().delete(`videos/${id}`);
  },
  getVideoFeeling(id) {
    return Api().get(`videos/${id}/feeling`);
  },
  setVideoFeeling(id, data) {
    return Api().put(`videos/${id}/feeling`, data);
  },
  deleteVideoFeeling(id) {
    return Api().delete(`videos/${id}/feeling`);
  },
  getMostRecentVideoView(id) {
    return Api().get(`videos/${id}/views/last`);
  },
  createView(id, data) {
    return Api().post(`videos/${id}/views`, data);
  },
  deleteVideoViews(id) {
    return Api().delete(`videos/${id}/views`);
  },
  reportVideo(data) {
    return Api().post(`videos/${data.videoId}/reports`, data);
  },
};
