<!-- © 2021 Tfarraj -->
<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="fab-transition"
    max-width="1200"
    id="settings"
  >
    <v-card tile>
      <div class="d-flex justify-space-between mb-5" id="modal-header">
        <v-card-title class="py-3">{{
          $t('settingsModal.settings')
        }}</v-card-title>
        <v-btn icon text @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <template>
        <v-card>
          <v-tabs v-model="tabsModel" centered slider-color="yellow">
            <v-tab key="userTab">
              {{ $t('settingsModal.user') }}
            </v-tab>
            <v-tab key="passwordTab">
              {{ $t('settingsModal.password') }}
            </v-tab>
            <v-tab key="channelTab"> {{ $t('settingsModal.channel') }} </v-tab>
            <v-tab key="streamTab">
              {{ $t('settingsModal.stream') }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabsModel">
            <v-tab-item key="userTab">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="8"
                      lg="8"
                      class="pt-5"
                    >
                      <v-alert
                        v-if="alert.userForm.type"
                        dense
                        outlined
                        prominent
                        text
                        :type="alert.userForm.type"
                      >
                        {{ alert.userForm.text }}
                      </v-alert>
                      <ValidationObserver
                        ref="userForm"
                        v-slot="{ handleSubmit, reset }"
                      >
                        <form
                          @submit.prevent="handleSubmit(submitUserForm)"
                          @reset.prevent="reset"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="formDataUser.email"
                              v-bind:error-messages="
                                errors != ''
                                  ? $t('settingsModal.' + errors)
                                  : ''
                              "
                              v-bind:label="$t('settingsModal.email')"
                              filled
                              dense
                            >
                            </v-text-field>
                          </ValidationProvider>
                          <ValidationProvider>
                            <div>
                              <v-select
                                :items="languages"
                                item-text="name"
                                v-model="formDataUser.preferredLanguage"
                                v-bind:label="
                                  $t('settingsModal.preferredLanguage')
                                "
                              >
                              </v-select>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            ><v-text-field
                              v-model="userStatus"
                              v-bind:label="$t('settingsModal.userStatus')"
                              readonly
                              disabled
                            ></v-text-field>
                          </ValidationProvider>
                          <v-btn
                            :loading="loading.userForm"
                            type="submit"
                            class="primary"
                            depressed
                            >{{ $t('settingsModal.submit') }}
                          </v-btn>
                        </form>
                      </ValidationObserver>
                    </v-col>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="pt-5"
                    >
                      <h3 class="mb-6">
                        {{ $t('settingsModal.userActions') }}
                      </h3>
                      <li class="mr-2 ml-2">
                        <a
                          :loading="loading.removeMyUser"
                          @click="submitRemoveMyUser"
                          depressed
                          class="red--text small"
                          >{{ $t('settingsModal.removeMyUser') }}
                        </a>
                      </li>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="passwordTab">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="8"
                      lg="8"
                      class="pt-0"
                    >
                      <h3 class="mb-6 mt-6">
                        {{ $t('settingsModal.changePassword') }}
                      </h3>
                      <v-alert
                        v-if="alert.passwordForm.type"
                        dense
                        outlined
                        prominent
                        text
                        :type="alert.passwordForm.type"
                      >
                        {{ alert.passwordForm.text }}
                      </v-alert>
                      <ValidationObserver
                        ref="passwordForm"
                        v-slot="{ handleSubmit, reset }"
                      >
                        <form
                          @submit.prevent="handleSubmit(submitPasswordForm)"
                          @reset.prevent="reset"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="currentPassword"
                            rules="required|min:8"
                          >
                            <v-text-field
                              v-model="formDataPassword.currentPassword"
                              :append-icon="
                                showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              v-bind:error-messages="
                                errors != ''
                                  ? $t('settingsModal.' + errors)
                                  : ''
                              "
                              :type="showCurrentPassword ? 'text' : 'password'"
                              name="currentPassword"
                              v-bind:label="$t('settingsModal.currentPassword')"
                              filled
                              dense
                              hint="At least 8 characters"
                              class="input-group--focused"
                              @click:append="
                                showCurrentPassword = !showCurrentPassword
                              "
                            ></v-text-field>
                          </ValidationProvider>
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="newPassword"
                            rules="required|min:8"
                          >
                            <v-text-field
                              v-model="formDataPassword.newPassword"
                              :append-icon="
                                showNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              v-bind:error-messages="
                                errors != ''
                                  ? $t('settingsModal.' + errors)
                                  : ''
                              "
                              :type="showNewPassword ? 'text' : 'password'"
                              name="newPassword"
                              v-bind:label="$t('settingsModal.newPassword')"
                              filled
                              dense
                              hint="At least 8 characters"
                              class="input-group--focused"
                              @click:append="showNewPassword = !showNewPassword"
                            ></v-text-field>
                          </ValidationProvider>

                          <v-btn
                            :loading="loading.passwordForm"
                            type="submit"
                            class="primary"
                            depressed
                            >{{ $t('settingsModal.submit') }}</v-btn
                          >
                        </form>
                      </ValidationObserver>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="channelTab">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <!--   <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="8"
                      lg="8"
                      class="pt-0"
                    >
                      <ValidationObserver
                        ref="channelForm"
                        v-slot="{ handleSubmit, reset }"
                      >
                        <form
                          @submit.prevent="handleSubmit(submitChannelForm)"
                          @reset.prevent="reset"
                        >
                          <ValidationProvider name="Channel Name"
                            ><v-text-field
                              v-model="formData.channelName"
                              v-bind:label="$t('settingsModal.channelName')"
                              readonly
                            ></v-text-field>
                          </ValidationProvider>
                          <v-btn
                            :loading="loading.channelForm"
                            type="submit"
                            class="primary"
                            depressed
                            >{{ $t('settingsModal.submit') }}
                          </v-btn>
                        </form>
                      </ValidationObserver>
                    </v-col>-->
                    <v-col
                      order-sm="1"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="text-center"
                    >
                      <v-btn @click="toggleShow">{{
                        $t('settingsModal.uploadAvatar')
                      }}</v-btn>
                      <my-upload
                        field="photo"
                        method="PUT"
                        :headers="headers"
                        @crop-success="cropSuccess"
                        @crop-upload-success="cropUploadSuccess"
                        @crop-upload-fail="cropUploadFail"
                        v-model="show"
                        :url="url"
                        :width="200"
                        :height="200"
                        :noRotate="false"
                        img-format="jpg"
                        langType="en"
                      ></my-upload>
                      <v-responsive
                        width="230"
                        class="mx-auto"
                        style="border-radius: 50%"
                      >
                        <v-img
                          v-if="imgDataUrl"
                          height="220"
                          :src="imgDataUrl"
                        ></v-img>
                        <img-with-auth
                          v-else-if="getChannel.photo"
                          :id="getChannel.id"
                          :type="'channel'"
                        />
                        <div
                          v-else
                          class="px-12"
                          id="settings-image-placeholder"
                        >
                          <v-icon>mdi-image-plus</v-icon>
                        </div>
                      </v-responsive>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="streamTab">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      class="pt-5"
                    >
                      <v-alert
                        v-if="alert.streamForm.type"
                        dense
                        outlined
                        prominent
                        text
                        :type="alert.streamForm.type"
                      >
                        {{ alert.streamForm.text }}
                      </v-alert>
                      <ValidationObserver
                        ref="streamForm"
                        v-slot="{ handleSubmit, reset }"
                      >
                        <form
                          class="mt-6"
                          @submit.prevent="handleSubmit(submitStreamForm)"
                          @reset.prevent="reset"
                        >
                          <ValidationProvider>
                            <v-row class="mb-3 ml-2 mr-2">
                              <v-text-field
                                v-model="getStreamKey"
                                v-bind:label="$t('settingsModal.streamKey')"
                                readonly
                                disabled
                              ></v-text-field>
                              <v-btn
                                :loading="loading.generateStreamKey"
                                @click="submitGenerateStreamKey"
                                class="primary mr-2 ml-2"
                                depressed
                                >{{
                                  getStreamKey
                                    ? $t('settingsModal.regenerateStreamKey')
                                    : $t('settingsModal.generateStreamKey')
                                }}
                              </v-btn>
                              <v-btn
                                color="error"
                                :loading="loading.removeStreamKey"
                                @click="submitRemoveStreamKey"
                                class="mr-2"
                                depressed
                                >{{ $t('settingsModal.removeStreamKey') }}
                              </v-btn>
                            </v-row>
                          </ValidationProvider>
                          <hr class="mb-6" />
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="Visibility"
                            rules="required"
                          >
                            <v-select
                              v-model="formDataStream.defaultVisibility"
                              :items="videoVisibility"
                              :error-messages="
                                errors != ''
                                  ? $t('uploadVideoModal.' + errors)
                                  : ''
                              "
                              filled
                              :label="
                                $t('settingsModal.defaultLiveStreamVisibility')
                              "
                              item-value="key"
                              item-text="value"
                            >
                              <template #selection="{ item }">
                                {{ $t('visibility.' + item.value) }}
                              </template>
                              <template #item="{ item }">
                                {{ $t('visibility.' + item.value) }}
                              </template>
                            </v-select>
                          </ValidationProvider>
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="Category"
                            rules="required"
                          >
                            <v-autocomplete
                              v-model="formDataStream.defaultCategory"
                              :items="categories"
                              item-text="value"
                              item-value="key"
                              :error-messages="
                                errors != ''
                                  ? $t('uploadVideoModal.' + errors)
                                  : ''
                              "
                              filled
                              :label="
                                $t('settingsModal.defaultLiveStreamCategory')
                              "
                              :loading="loading.category"
                            />
                          </ValidationProvider>
                          <v-btn
                            type="submit"
                            :loading="loading.streamFormSubmitLoading"
                            class="primary mr-2"
                            depressed
                            >{{ $t('settingsModal.submit') }}
                          </v-btn>
                        </form>
                      </ValidationObserver>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { mapGetters } from 'vuex';
import Config from '@/config';
import UserService from '@/services/UserService';
import ChannelService from '@/services/ChannelService';
import CategoryService from '@/services/CategoryService';
import ImgWithAuth from '@/components/ImgWithAuth';
import LocalizationMixin from '@/mixins/LocalizationMixin.ts';
import AuthMixin from '@/mixins/AuthMixin.ts';
import CommonMixin from '@/mixins/CommonMixin.ts';

export default {
  name: 'SettingsModal',
  mixins: [CommonMixin, LocalizationMixin, AuthMixin],
  props: ['openDialog'],
  data() {
    return {
      tabsModel: 'tabsModel',
      languages: [
        { value: 'ar', name: 'Arabic' },
        { value: 'en', name: 'English' },
      ],
      showCurrentPassword: false,
      showNewPassword: false,
      valid: false,
      interval: {},
      value: 0,
      show: false,
      categories: [],
      categoriesData: [],
      rules: [
        (value) =>
          !value ||
          value.size < 5159367048 ||
          'Video size should be less than 5 GB!',
      ],
      formDataUser: {
        // channelName: this.$store.getters.getChannel.title,
        email: this.$store.getters.getUser.email,
        preferredLanguage: this.$store.getters.getUser.preferredLanguage,
      },
      formDataStream: {
        defaultCategory: this.$store.getters.getUser.defaultCategory,
        defaultVisibility: this.$store.getters.getUser.defaultVisibility,
      },
      formDataPassword: {
        currentPassword: '',
        newPassword: '',
      },
      loading: {
        channelForm: false,
        userForm: false,
        removeMyUser: false,
        generateStreamKey: false,
        removeStreamKey: false,
        passwordForm: false,
        category: false,
        streamForm: false,
      },
      alert: {
        channelForm: { type: '', text: '' },
        userForm: { type: '', text: '' },
        passwordForm: { type: '', text: '' },
        streamForm: { type: '', text: '' },
      },
      items: Config.encodes,
      imgDataUrl: '',
      imgDataObj: [],
      url: '',
      headers: { Authorization: `Bearer ${this.$store.getters.getToken}` },
      userStatus: this.$store.getters.getIsUserActive
        ? this.$i18n.t('settingsModal.verified')
        : this.$i18n.t('settingsModal.unverified'),
    };
  },
  watch: {
    getDisplayLanguage() {
      this.getCategories();
      this.userStatus = this.$store.getters.getIsUserActive
        ? this.$i18n.t('settingsModal.verified')
        : this.$i18n.t('settingsModal.unverified');
    },
  },
  computed: {
    ...mapGetters([
      'getIsUserActive',
      'getUser',
      'getUrl',
      'getChannel',
      'getDisplayLanguage',
      'getStreamKey',
    ]),

    dialog() {
      return this.openDialog;
    },
  },
  methods: {
    async getCategories() {
      console.log('getStreamKey', this.getStreamKey);
      this.loading.category = true;
      const categoriesResponse = await CategoryService.getAll()
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => (this.loading.category = false));
      this.categoriesData = categoriesResponse.data.data;
      this.updateCategoriesLanguage();
    },
    updateCategoriesLanguage() {
      this.categories = this.categoriesData.map((category) => ({
        key: category._id,
        value:
          this.getDisplayLanguage === 'en' ? category.nameEn : category.nameAr,
      }));
    },
    copyText() {
      this.$refs.code1.select();
      document.execCommand('copy');
    },
    async submitChannelForm() {
      if (this.loading.channelForm) return;
      this.loading.channelForm = true;

      if (this.imgDataUrl) {
        const fd = new FormData();
        if (this.imgDataUrl !== '') {
          fd.append('photo', this.imgDataObj, 'channelPhoto.jpg');
        }
        const photo = await ChannelService.updateChannelPhoto(
          this.getChannel.id,
          fd,
          {
            onUploadProgress: (uploadEvent) => {
              this.value = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100,
              );
            },
          },
        )
          .catch((err) => {
            // console.log(err);
          })
          .finally(() => (this.loading.channelForm = false));
        if (photo) {
          this.$store.dispatch('getChannelPhoto', this.getChannel.id);
          this.closeModal();
        }
      }
    },
    async submitUserForm() {
      if (this.loading.userForm) return;
      this.loading.userForm = true;
      this.resetAlerts();
      try {
        const user = await UserService.updateMe({
          email: this.formDataUser.email,
          preferredLanguage: this.formDataUser.preferredLanguage,
        });
        if (!user) return;
        await this.$store.dispatch('retrieveUser');
        this.setLocale();
        if (this.formDataUser.email !== this.$store.getters.getUser.email) {
          this.alert.userForm.type = 'success';
          this.alert.userForm.text = this.$i18n.t(
            'settingsModal.emailChangedRequested',
          );
        } else {
          this.alert.userForm.type = 'success';
          this.alert.userForm.text = this.$i18n.t(
            'settingsModal.savedSuccessfully',
          );
        }
      } catch (err) {
        const error = err.response.data
          ? err.response.data.error[0].message
          : '';
        if (error === 'email already exists') {
          this.$refs.userForm.setErrors({
            email: 'emailAlreadyExists',
          });
        } else if (error === 'Please provide a valid email') {
          this.$refs.userForm.setErrors({
            email: 'Email must be valid',
          });
        } else {
          this.alert.userForm.type = 'warning';
          this.alert.userForm.text = this.$i18n.t('settingsModal.savedFailed');
        }
      } finally {
        this.loading.userForm = false;
      }
    },
    async submitPasswordForm() {
      if (this.loading.passwordForm) return;
      this.loading.passwordForm = true;
      this.resetAlerts();
      this.showPasswordChangedAlert = false;
      this.incorrectPasswordAlert = false;

      try {
        const { data } = await UserService.updatePassword({
          currentPassword: this.formDataPassword.currentPassword,
          newPassword: this.formDataPassword.newPassword,
        });
        this.$store.dispatch('updateChatToken', data.chatToken);
        this.alert.passwordForm.type = 'success';
        this.alert.passwordForm.text = this.$i18n.t(
          'settingsModal.emailChangedRequested',
        );
        this.formDataPassword.currentPassword = '';
        this.formDataPassword.newPassword = '';
        this.$refs.passwordForm.reset();
      } catch (err) {
        const errors = err.response.data ? err.response.data.error : null;
        if (errors) {
          this.$refs.passwordForm.setErrors({
            currentPassword:
              errors === 'Current password is incorrect' ? errors : '',
          });
          const newPasswordValidationError = errors.find(
            (error) => error.field === 'password',
          )
            ? errors.find((error) => error.field === 'password')
            : [];
          this.$refs.passwordForm.setErrors({
            newPassword: newPasswordValidationError.message,
          });
        } else {
          this.alert.passwordForm.type = 'warning';
          this.alert.passwordForm.text = this.$i18n.t(
            'settingsModal.savedFailed',
          );
        }
      } finally {
        this.loading.passwordForm = false;
      }
    },
    async submitRemoveMyUser() {
      if (this.loading.removeMyUser) return;
      this.loading.removeMyUser = true;
      this.resetAlerts();
      var data = await this.removeMyUser();
      if (data === 'Channel has videos') {
        this.alert.userForm.type = 'warning';
        this.alert.userForm.text = this.$i18n.t(
          'settingsModal.channelHasVideos',
        );
      }
      this.loading.removeMyUser = false;
    },
    async submitGenerateStreamKey() {
      if (this.loading.generateStreamKey) return;
      this.loading.generateStreamKey = true;
      this.$store.dispatch('generateStreamKey', this.getChannel.id);
      this.loading.generateStreamKey = false;
    },
    async submitRemoveStreamKey() {
      if (this.loading.removeStreamKey) return;
      this.loading.removeStreamKey = true;
      this.$store.dispatch('removeStreamKey', this.getChannel.id);
      this.loading.removeStreamKey = false;
    },
    async submitStreamForm() {
      if (this.loading.streamFormSubmitLoading) return;
      this.resetAlerts();
      this.loading.streamFormSubmitLoading = true;
      this.$store.dispatch('streamFormSubmit', {
        defaultCategory: this.formDataStream.defaultCategory,
        defaultVisibility: this.formDataStream.defaultVisibility,
      });
      this.alert.streamForm.type = 'success';
      this.alert.streamForm.text = this.$i18n.t(
        'settingsModal.savedSuccessfully',
      );
      this.loading.streamFormSubmitLoading = false;
    },
    closeModal() {
      this.$emit('closeDialog');
    },
    toggleShow() {
      this.show = !this.show;
    },
    resetAlerts() {
      this.alert = {
        channelForm: { type: '', text: '' },
        userForm: { type: '', text: '' },
        passwordForm: { type: '', text: '' },
        streamForm: { type: '', text: '' },
      };
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async cropSuccess(imgDataUrl, field) {
      const file = this.dataURLtoBlob(imgDataUrl);
      this.imgDataObj = file;
      this.imgDataUrl = imgDataUrl;
    },
    cropUploadSuccess(jsonData, field) {
      // this.$store.dispatch('getChannelPhoto', this.getChannel.id);
    },
    cropUploadFail(status, field) {
      // console.log("-------- upload fail --------");
      // console.log(status);
      // console.log("field: " + field);
    },
  },
  async mounted() {
    this.getCategories();
  },
  beforeMount() {},
  components: {
    myUpload,
    ImgWithAuth,
  },
};
</script>

<style lang="scss">
#modal-header {
  border-bottom: 1px solid rgb(238, 232, 232);
}

#settings-image-placeholder {
  padding-top: 7em;
  padding-bottom: 7em;
  border: 2px dashed rgb(209, 209, 209);
  border-radius: 50%;
}

@media (max-width: 700px) {
  .vue-image-crop-upload {
    .vicp-wrap {
      width: 95%;
      height: 550px;

      .vicp-step2 .vicp-crop {
        .vicp-crop-left {
          float: none;

          .vicp-range {
            margin: auto;
            margin-top: 30px;
          }

          & * {
            margin-left: auto;
            margin-right: auto;
          }
        }
        .vicp-crop-right {
          float: none;
          margin-top: 30px;
          .vicp-preview .vicp-preview-item {
            display: inline-block;
            float: none;
          }
          & * {
            // margin-left: auto;
            // margin-right: auto;
          }
        }
      }
    }
  }
}
</style>
